<template>
  <action-dispatcher action="user/get">
    <div class="view-my-account pb-5">
      <div class="container">
        <div class="row justify-content-center py-5">
          <div class="col-lg-6 items-inputs">
            <h2 class="text-center text-uppercase mb-4">Minha conta</h2>
            <form-group
              v-model="form.name"
              id="title"
              label="Nome completo"
              placeholder="Seu nome"
            />
            <form-group
              v-model="form.email"
              disabled
              id="email"
              blackShape
              is-login
              label="Email"
              placeholder="Seu email"
              class="mb-4"
            />
            <label class="styled-label" for="phone-wrapper"
              >Número de telefone</label
            >
            <div id="phone-wrapper" class="row mb-4">
              <div class="col-lg-4">
                <form-group
                  v-model="form.ddd"
                  id="ddd"
                  placeholder="Seu ddd"
                  masked
                  mask="##"
                />
              </div>
              <div class="col-lg-8">
                <form-group
                  v-model="form.phone"
                  id="phone"
                  masked
                  mask="# ####-####"
                  placeholder="Seu número de telefone"
                />
              </div>
            </div>
            <div class="text-center">
              <router-link
                class="btn text-light mb-4"
                :to="{ name: 'change-password' }"
              >
                Alterar senha
              </router-link>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <button
                  class="btn btn-outline-secondary btn-block"
                  @click="$router.go(-1)"
                >
                  Voltar
                </button>
              </div>
              <div class="col-lg-6">
                <button class="btn btn-primary btn-block" @click="submit">
                  <loading :show="showLoading">
                    Salvar
                  </loading>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>
<script>
import {
  formFields,
  removeMask,
  firstLetterUppercase
} from "@/functions/helpers";
import { mapState } from "vuex";
export default {
  data() {
    return {
      ...formFields(["user", "name", "email", "ddd", "phone"]),
      showLoading: false
    };
  },
  computed: {
    ...mapState("user", [])
  },
  mounted() {
    for (let k in this.form) {
      if (k == "ddd") continue;

      if (k == "phone" && this.$user.user[k]) {
        this.form["ddd"] = `${this.$user.user[k]}`.slice(0, 2);
        this.form["phone"] = `${this.$user.user[k]}`.slice(2, 11);
        continue;
      }

      this.form[k] = this.$user.user[k];
    }
    this.form.user = this.$user.user.id;
  },
  methods: {
    submit() {
      if (!this.form.name || !this.form.name.length) {
        this.$message.warning("Digite seu nome");
        return;
      }

      if (!this.form.email || !this.form.email.length) {
        this.$message.warning("Digite seu e-mail");
        return;
      }

      if (
        !this.form.ddd ||
        !this.form.phone ||
        !this.form.ddd.length ||
        !this.form.phone.length
      ) {
        this.$message.warning("Digite o telefone com ddd");
        return;
      }

      this.showLoading = true;
      this.form.name = firstLetterUppercase(this.form.name);
      const payload = {
        ...this.form,
        phone: this.form.ddd + removeMask(this.form.phone.trim())
      };

      delete payload.ddd;

      this.$store
        .dispatch("user/update", payload)
        .then(rep => {
          this.$message.success("Os dados da conta foram atualizados!");
        })
        .catch(err => {
          const data = err.response.data;
          const msg =
            data.error ||
            data.detail ||
            "Não foi possível atualizar as informações da conta";
          this.$message.error(msg);
        })
        .finally(() => {
          this.showLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-my-account {
  .styled-label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #595959;
  }

  .items-inputs ::v-deep input {
    color: white;
  }
}
</style>
